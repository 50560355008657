import { render, staticRenderFns } from "./CompositorHitsEditarLetraCard.vue?vue&type=template&id=f4816986&"
import script from "./CompositorHitsEditarLetraCard.vue?vue&type=script&lang=js&"
export * from "./CompositorHitsEditarLetraCard.vue?vue&type=script&lang=js&"
import style0 from "./CompositorHitsEditarLetraCard.vue?vue&type=style&index=0&id=f4816986&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports