<template>
  <b-card title="Dados do Hit">
    <b-form @submit.prevent="logFormData">

      <b-row>

        <b-col cols="12" xl="12">
          
            <label for="hitComposicao">Composição:</label>
            <b-form-input id="hitComposicao" v-model="hit.nomeComposicao"></b-form-input>
            <label class="mt-1" for="hitCompositores">Compositores:</label>
            <b-form-input id="hitCompositores" v-model="hit.nomeCompositores"></b-form-input>
            
            <b-form-group label="Tema" label-for="hitTemas">
              <label class="mt-1" for="hitTemas">Temas:</label>
              <validation-provider name="hitTema">
                <v-select v-model="hit.listaTemas" multiple :reduce="tema => tema.id" :key="temas.id" :options="temas" label="nome"></v-select>
              </validation-provider>
            </b-form-group>
  
            <b-form-group label="Estilo" label-for="hitEstilos">
              <label class="mt-1" for="hitEstilos">Estilo:</label>
              <validation-provider name="hitEstilo">
                <v-select v-model="hit.listaEstilos" multiple :reduce="estilo => estilo.id" :key="estilos.id" :options="estilos" label="nome"></v-select>
              </validation-provider>
            </b-form-group>
                
                <b-row class="mt-1">
                  <b-col cols="12" xl="6">
                    <label for="hitCompositores">Data de Cadastro:</label>
                    <b-form-input id="hitCompositores" v-model="hit.dataCadastro" readonly></b-form-input>
                  </b-col>

                  <b-col cols="12" xl="6">
                    <label for="hitStatus">Status de Aprovação</label>
                    <v-select v-model="hit.statusAprovacaoHit" :options="statusOptions" placeholder="Selecione o status" :disabled="true"/>
                  </b-col>  
                </b-row>
        </b-col>
      </b-row>

      <b-card class="mb-5 mt-2">
        <b-row class="justify-content-between mx-2">
          <div class="d-flex align-items-center">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                R$ {{ hit.valorLiberacao }}
              </h5>
              <small>Liberação</small>
            </div>
          </div>
          
          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                R$ {{ hit.valorExclusividade }}
              </h5>
              <small>Exclusividade</small>
            </div>
          </div>
        </b-row>
          
      </b-card>
      <b-row class="mx-1 justify-content-between">
          <b-button
              variant="primary"
              type="submit"
              :disabled="invalid || respostaApi"
              @click="register"
            >
              Salvar
              <b-spinner v-if="respostaApi" small label="Carregando" />
          </b-button>

          <b-button
              variant="danger"
              type="submit"
              :disabled="invalid || respostaApi"
             
            >
              Desativar Hit
              <b-spinner v-if="respostaApi" small label="Carregando" />
          </b-button>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormFile, BModal, BSpinner,
  BFormGroup, BForm, 
} from 'bootstrap-vue'
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt'
import { ValidationProvider, ValidationObserver } from 'vee-validate'



export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormInput,
    BFormFile,
    BForm,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    hit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      invalid: false,
      statusOptions: [],
      estilos: [],
      temas: [],
      primeiraAtualizacao: true,
      respostaApi: false,
    };
  },
  created() {
    this.hitEstilosLista();
    this.hitTemasLista();
  },
  methods: {
    hitEstilosLista() {
        useJwt.hitEstilosLista({
          page: 0,
          size: 999,
          sort: 'ASC',
        })
          .then(response => {
            response.data.content.forEach(estilo => {
              this.estilos.push({ nome: estilo.nome, id: estilo.id })
            })

          })
          .catch(error => {
            console.log(error)
          })
    },
    hitTemasLista() {
        useJwt.hitTemasLista({
          page: 0,
          size: 999,
          sort: 'ASC',
        })
          .then(response => {
            response.data.content.forEach(tema => {
              this.temas.push({ nome: tema.nome, id: tema.id })
            })
          })
          .catch(error => {
            console.log(error)
          })
    },
    transformListToIds(list) {
      return list.map(item => item.id || item);
    },
    register() {
      this.respostaApi = true

      const hitData = {
        ...this.hit,
        listaEstilos: this.transformListToIds(this.hit.listaEstilos),
        listaTemas: this.transformListToIds(this.hit.listaTemas)
      };

      useJwt.hitGestorEditar(hitData)
      .then(response => {
        this.$swal({
          title: 'Sucesso!',
          text: 'Os dados foram salvos com sucesso.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        }).then(() => {
          this.$router.go()
        })
        this.respostaApi = false
      })
      .catch(error => {
        this.$swal({
          title: 'Erro!',
          text: 'Ocorreu um erro ao salvar os dados.',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.respostaApi = false
      })
    }
  }
}
</script>

<style lang="scss">

</style>
